<template>
  <nav
    v-if="authUser"
    :class="{ short: short }"
  >
    <router-link
      v-tooltip="tooltipTitle('Dashboard')"
      :to="{ name: 'home' }"
      :class="{'router-link-active': dashboardActive}"
      exact
    >
      <r-icon
        variant
        icon-style="fas"
        name="th-large"
      />
      <span>Dashboard</span>
    </router-link>
    <router-link
      v-if="canNavigate('users.index')"
      v-tooltip="tooltipTitle('Users')"
      :to="{ name: 'users.index' }"
    >
      <r-icon
        variant
        icon-style="fad"
        name="users"
      />
      <span>Users</span>
    </router-link>
    <router-link
      v-if="canNavigate('areas.index')"
      v-tooltip="tooltipTitle('Areas')"
      :to="{ name: 'areas.index' }"
    >
      <r-icon
        variant=""
        icon-style="fas"
        name="circle-location-arrow"
      />
      <span>Areas</span>
    </router-link>
    <router-link
      v-if="canNavigate('companies.index')"
      v-tooltip="tooltipTitle('Companies')"
      :to="{ name: 'companies.index' }"
    >
      <r-icon
        variant
        icon-style="fad"
        name="building"
      />
      <span>Companies</span>
    </router-link>
    <router-link
      v-if="canNavigate('policies.index')"
      v-tooltip="tooltipTitle('Policies')"
      :to="{ name: 'policies.index' }"
    >
      <r-icon
        variant
        icon-style="fad"
        name="clipboard"
      />
      <span>Policies</span>
    </router-link>
    <router-link
      v-if="canNavigate('policy-options.index')"
      v-tooltip="tooltipTitle('Policy Options')"
      :to="{ name: 'policy-options.index' }"
    >
      <r-icon
        variant
        icon-style="fad"
        name="option"
      />
      <span>Policy Options</span>
    </router-link>
    <router-link
      v-if="canNavigate('services.index')"
      v-tooltip="tooltipTitle('Services')"
      :to="{ name: 'services.index' }"
    >
      <r-icon
        variant
        icon-style="fad"
        name="concierge-bell"
      />
      <span>Services</span>
    </router-link>
    <router-link
      v-if="canNavigate('billing-terms.index')"
      v-tooltip="tooltipTitle('Billing Terms')"
      :to="{ name: 'billing-terms.index' }"
    >
      <r-icon
        variant
        icon-style="fad"
        name="file-invoice-dollar"
      />
      <span>Billing Terms</span>
    </router-link>
    <router-link
      v-if="canNavigate('linkables.index')"
      v-tooltip="tooltipTitle('Linkables')"
      :to="{ name: 'linkables.index' }"
    >
      <r-icon
        variant
        icon-style="fad"
        name="link"
      />
      <span>Linkables</span>
    </router-link>
    <router-link
      v-if="canNavigate('links.index')"
      v-tooltip="tooltipTitle('Links')"
      :to="{ name: 'links.index' }"
    >
      <r-icon
        variant
        icon-style="fad"
        name="link-simple"
      />
      <span>Links</span>
    </router-link>
    <router-link
      v-if="canNavigate('communities.index')"
      v-tooltip="tooltipTitle('Communities')"
      :to="{ name: 'communities.index' }"
    >
      <r-icon
        variant
        icon-style="fad"
        name="hand-holding-hand"
      />
      <span>Communities</span>
    </router-link>
    <router-link
      v-if="canNavigate('forms.index')"
      v-tooltip="tooltipTitle('Forms')"
      :to="{ name: 'forms.index' }"
    >
      <r-icon
        variant
        icon-style="fad"
        name="file-alt"
      />
      <span>Forms</span>
    </router-link>
    <router-link
      v-if="canNavigate('service-categories.index')"
      v-tooltip="tooltipTitle('Service Categories')"
      :to="{ name: 'service-categories.index' }"
    >
      <r-icon
        variant
        icon-style="fad"
        name="album-collection"
      />
      <span>Service Categories</span>
    </router-link>
    <router-link
      v-if="canNavigate('products.index')"
      v-tooltip="tooltipTitle('Packages')"
      :to="{ name: 'products.index' }"
    >
      <r-icon
        variant
        icon-style="fad"
        name="shopping-cart"
      />
      <span>Packages</span>
    </router-link>
    <router-link
      v-if="canNavigate('goals.index')"
      v-tooltip="tooltipTitle('Goals')"
      :to="{ name: 'goals.index' }"
    >
      <r-icon
        variant
        icon-style="fad"
        name="bullseye"
      />
      <span>Goals</span>
    </router-link>
    <router-link
      v-if="canNavigate('topics.index')"
      v-tooltip="tooltipTitle('Topics')"
      :to="{ name: 'topics.index' }"
    >
      <r-icon
        variant
        icon-style="fad"
        name="comments"
      />
      <span>Topics</span>
    </router-link>
    <router-link
      v-if="canNavigate('announcements.index')"
      v-tooltip="tooltipTitle('Announcements')"
      :to="{ name: 'announcements.index' }"
    >
      <r-icon
        variant
        icon-style="fad"
        name="megaphone"
      />
      <span>Announcements</span>
    </router-link>
    <router-link
      v-if="canNavigate('transferees.index')"
      v-tooltip="tooltipTitle('Transferees')"
      :to="{ name: 'transferees.index' }"
    >
      <r-icon
        variant
        icon-style="fad"
        name="clipboard-list"
      />
      <span>Transferees</span>
    </router-link>
    <router-link
      v-if="canNavigate('work-orders.index')"
      v-tooltip="tooltipTitle('Work Orders')"
      :to="{ name: 'work-orders.index' }"
    >
      <r-icon
        variant
        icon-style="fad"
        name="folder-open"
      />
      <span>Work Orders</span>
    </router-link>
  </nav>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { tooltip } from '@relocity/ui'

export default {
  directives: {
    tooltip
  },
  props: {
    short: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters('auth', ['role']),
    ...mapState('auth', ['authUser']),
    dashboardActive () {
      // exact is no longer matching "/" to add this class because of the query params
      // This overrides the exact match.  Removing exact makes it always highlighted
      return this.$route.name === 'home'
    }
  },
  methods: {
    canNavigate (routeName) {
      return this.$can(routeName, 'read')
    },
    tooltipTitle (section) {
      return this.short ? section : false
    }
  }
}
</script>

<style scoped>
nav {
  a {
    @apply group flex items-center px-2 py-2 text-base leading-6 font-medium rounded-md text-white hover:text-primary hover:bg-white focus:outline-none focus:text-primary focus:bg-white transition ease-in-out duration-150 whitespace-no-wrap;

    i {
      @apply px-2;
    }

    &.router-link-active {
      @apply text-primary bg-white;
    }
  }

  &.short {
    a {
      span {
        @apply hidden;
      }

      i {
        @apply w-full text-center px-0 text-2xl;
      }
    }
  }
}
</style>
