import http from '@/services/http'

const state = {
  codes: [],
  code: null,
  types: null
}

// getters
const getters = {
  codes: ({ codes }) => codes
}

// actions
const actions = {
  async all ({ commit }) {
    const { data } = await http.get('codes')
    commit('SET_CODES', data.data)
    return data.data
  },
  async create (c, payload) {
    try {
      const { data } = await await http.post('codes', payload)
      return data.data
    } catch (e) {
      return Promise.reject(e)
    }
  },
  async get ({ commit }, id) {
    try {
      const { data } = await http.get(`codes/${id}`)
      commit('SET_CODE', data.data)
    } catch (e) {
      return Promise.reject(e)
    }
  },
  async update ({ commit }, { id, payload }) {
    try {
      const { data } = await http.patch(`codes/${id}`, payload)
      commit('SET_CODE', data.data)
    } catch (e) {
      return Promise.reject(e)
    }
  },
  async delete (c, id) {
    try {
      await http.delete(`codes/${id}`)
    } catch (e) {
      return Promise.reject(e)
    }
  },
  async getTypes ({ commit }) {
    try {
      if (state.types) {
        return state.types
      }

      const { data } = await http.get('codes/types')
      commit('SET_TYPES', data.data)
      return data.data
    } catch (e) {
      return Promise.reject(e)
    }
  }
}

// mutations
const mutations = {
  SET_CODES (state, codes) {
    state.codes = codes
  },
  SET_CODE (state, value) {
    state.code = value
  },
  CLEAR_CODE (state) {
    state.code = {}
  },
  SET_TYPES (state, value) {
    state.types = value
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
