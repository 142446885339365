import http from '@/services/http'
const state = {
}
// getters
const getters = {
}
// actions
const actions = {
  async updateProgramDelegatePermission ({ commit }, { programId, payload }) {
    try {
      await http.patch(`programs/${programId}/delegate-permissions`, payload)
    } catch (e) {
      return Promise.reject(e)
    }
  },
  async updatePolicyDelegatePermission ({ commit }, { policyId, payload }) {
    try {
      await http.patch(`policies/${policyId}/delegate-permissions`, payload)
    } catch (e) {
      return Promise.reject(e)
    }
  }
}
// mutations
const mutations = {
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
