<script>
import { mapActions, mapMutations, mapGetters } from 'vuex'
import FirebaseMixin from '@/mixins/firebase'

export default {
  mixins: [FirebaseMixin],
  computed: {
    ...mapGetters('auth', ['token', 'authUser', 'intercomProfile'])
  },
  watch: {
    token () {
      if (this.token !== null) {
        this.startup()
      }
    }
  },
  created () {
    this.$auth.onAuthenticated = (accessToken) => this.setToken(accessToken)

    if (this.token) {
      this.startup()
    }
  },
  methods: {
    ...mapActions('auth', ['getProfile']),
    ...mapMutations('auth', {
      setToken: 'SET_AUTH_TOKEN',
      setAuthUser: 'SET_AUTH_USER'
    }),
    async startup () {
      if (this.$intercom) {
        await this.$intercom.shutdown()
      }
      await this.getProfile()
      await this.bootIntercom()
    },
    async bootIntercom () {
      if (
        (
          this.authUser?.has_employee_profile ||
          this.isSuperAgent ||
          this.isAgent) &&
        process.env.VUE_APP_INTERCOM_APP_ID
      ) {
        this.$intercom.boot(this.intercomProfile)
      }
    }
  }
}
</script>

<template>
  <div>
    <router-view />
    <r-notification expand="hover" />
  </div>
</template>
