import http from '@/services/http'
const state = {
}
// getters
const getters = {
}
// actions
const actions = {
  async getCompanyDelegateAssetAssociations ({ commit }, { companyId }) {
    try {
      const { data } = await http.get(`companies/${companyId}/delegate-asset-associations`)
      return data.data
    } catch (e) {
      return Promise.reject(e)
    }
  },
  async syncCompanyDelegateAssetAssociations ({ commit }, { companyId, payload }) {
    try {
      await http.post(`companies/${companyId}/delegate-asset-associations`, payload)
    } catch (e) {
      return Promise.reject(e)
    }
  },
  async detachCompanyDelegateAssetAssociation ({ commit }, { companyId, companyDelegateAssetAssociationId }) {
    try {
      const { data } = await http.delete(`companies/${companyId}/delegate-asset-associations/${companyDelegateAssetAssociationId}`)
      return data.data
    } catch (e) {
      return Promise.reject(e)
    }
  }
}
// mutations
const mutations = {
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
