import http from '@/services/http'
const state = {
}
// getters
const getters = {
}
// actions
const actions = {
  async default (_) {
    try {
      const { data } = await http.get('delegate-email-templates/default')
      return data.data
    } catch (e) {
      return Promise.reject(e)
    }
  },
  async show (_, id) {
    try {
      const { data } = await http.get(`delegate-email-templates/${id}`)
      return data.data
    } catch (e) {
      return Promise.reject(e)
    }
  },
  async store (_, payload) {
    try {
      const { data } = await http.post('delegate-email-templates', payload)
      return data.data
    } catch (e) {
      return Promise.reject(e)
    }
  },
  async update (_, { id, payload }) {
    try {
      const { data } = await http.patch(`delegate-email-templates/${id}`, payload)
      return data.data
    } catch (e) {
      return Promise.reject(e)
    }
  },
  async destroy (_, id) {
    try {
      await http.delete(`delegate-email-templates/${id}`)
    } catch (e) {
      return Promise.reject(e)
    }
  }
}
// mutations
const mutations = {
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
